.table-page-wrapper {
  .filter-160, .filter-180 {
    .gl-table-header {
      top: 52px;
    }

    .gl-table-body {
      top: 90px;
    }
  }
}

.datatable-container {
  left: 0;
  top: 0;
  margin-top: 52px;
  height: calc(100vh - 108px) !important;

  table {
    th, td {
      text-align: left;
      display: inherit;
    }
  }

  .gl-table-header {
    left: 0;
    width: 100%;
    top: 52px;
  }


  .gl-table-body {
    top: 90px;
    left: 0;
    right: 0;
  }
}
a.dashboard-responsive-nav-trigger {
  margin-top: 0;
  min-width: 50px;
  flex-basis: 50px;
  height: 50px;
  padding: 0;
  line-height: 45px;
  text-align: center;

  .hamburger {
    left: -11px;
  }
}
.app-notification {
  width: 80% !important;
  transform: translate(10%, 60px) !important;
  position: fixed !important;
}
.dashboard-box {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  box-shadow: 0 0 0;
}
.page-filter-bar {
  width: 100%;
  top: -1500px;
  left: 0;
  right: 0;
  background: #fff;
  height: 100vh !important;
  z-index: 9;
  padding-bottom: 110px;

  &.show {
    top: 90px;
  }
}
.gl-table-footer {
  width: 100% !important;
  left: 0 !important;

  .gl-preloader {
    position: absolute;
    right: 0;
    left: 0;
    text-align: right;
    padding-top: 7px;
  }
}
.gl-pagination-numbers {
  position: absolute;
  bottom: 60px;
  left: 0px;
  width: 100%;

  ul.pagination {
    align-items: center !important;
    justify-content: center !important;
  }
}
.user-profile-widget-1-3 {
  margin-bottom: 10px;
}
.single-client-profile-page {
  margin-top: 50px;

  .user-header-title {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }

  .user-header {
    margin-bottom: 15px;
    padding-bottom: 15px;

    .c-notification-button {
      float: left;
    }

    .profile-balance {
    }

    .profile-actions {
      float: right;
    }
  }
}
.single-client-box {
  table {
    table {
      tr {
        width: 100%;
        display: block;

        th {
          display: block !important;
          width: 100px !important;
          text-align: left;
          float: left;
        }

        td {
          width: calc(100% - 100px);
          float: left;
        }
      }
    }
  }

  #cp-status {
    span {
      float: right !important;
    }
  }
}
.order-status-view {
  span {
    width: auto;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  i {
    display: none;
  }
}
table.table td:last-child {
  border-bottom: 1px solid #dee2e6;
}
.user-invoices-box {
  margin-top: 0;
  margin-bottom: 0;

  .user-invoices-title, .user-invoices-create {
    width: 50%;
  }
}
.gl-table-body, .gl-table-header {
  tr {
    display: flex;
  }
}
.order-single-page {
  margin-top: 50px;

  .order-single-title {
    margin-top: 15px;
  }

  .invoice-client-detail {
    width: auto !important;
  }
}
.order-detail-table-right {
  tr {
    display: inline-block;
    width: 100%;

    th, td {
      float: left;
      display: block;
      border-bottom: 0 none !important;
    }

    th {
      width: 30%;
    }

    td {
      width: 70%;
    }

    td[colpan='2'] {
      width: 100%;
    }
  }
}
.order-send-buttons {
  .btn {
    margin-left: 3px;
    margin-right: 3px;
  }
}
.order-single-page {
  .order-total-input {
    width: 100%;
  }
}
table {
  tr {
    th {
      display: block;
    }
  }
}

.dashboard-nav-container {
  padding: 0;
}
.dashboard-sidebar-inner {
  padding-bottom: 0;
  overflow: hidden !important;
}
.admin-logged {
  display: none;
}
.dashboard-sidebar .dashboard-nav-container {
  padding: 0;
}
.user-welcome-text {
  font-size: 21px;
}
.dashboard-nav.active {
  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
  }
}