.cp-stat-boxes {
  .header {
    position: relative;
  }

  .icon {
    width: 3rem;
    height: 3rem;
  }

  .icon i {
    font-size: 2.25rem;
  }

  .icon-shape {
    display: inline-flex;
    padding: 12px;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  .icon-shape i {
    font-size: 1.25rem;
  }

  @media (min-width: 768px) {
    @keyframes show-navbar-dropdown {
      0% {
        transition: visibility .25s, opacity .25s, transform .25s;
        transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
        opacity: 0;
      }
      100% {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
    @keyframes hide-navbar-dropdown {
      from {
        opacity: 1;
      }
      to {
        transform: translate(0, 10px);
        opacity: 0;
      }
    }
  }

  @keyframes show-navbar-collapse {
    0% {
      transform: scale(.95);
      transform-origin: 100% 0;
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes hide-navbar-collapse {
    from {
      transform: scale(1);
      transform-origin: 100% 0;
      opacity: 1;
    }
    to {
      transform: scale(.95);
      opacity: 0;
    }
  }
}

.profile-widget {
  font-size: 18px;

  li {
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 300;

    strong {
      width: 125px;
      font-weight: 700;
      margin-right: 10px;
      //text-align: right;
      display: inline-block;
    }
  }
}

.user-panel-footer {
  height: 50px;
  position: fixed;
  bottom: 0;

  width: 1140px;
  left: 50%;
  margin-left: calc(-1140px / 2);

  text-align: right;
  padding-right: 120px;
  line-height: 50px;

  .footer-menu {
    display: inline-block;

    li {
      display: inline-block;
      margin-right: 10px;
      margin-left: 10px;

      a {
        color: #484848;
      }
    }
  }
  @media screen and(max-width: 720px) {
    display: none;
  }
}

.user-panel-top-menu {
  li.active {
    color: #1e5072;

    a {
      color: #1e5072;
    }
  }

  .profile-badge {
    margin-top: 14px !important;
    line-height: 30px;
  }
}
.page-container.w-50{
  @media screen and (max-width: 1024px){
    width: 100% !important;
  }
}
.client-panel-navbar {
  padding: 10px 5px !important;

  li {
    transition: background-color .15s;
    border-radius: 0.25rem;
  }

  li, li a {
    user-select: none;
  }

  li.active {
    background-color: rgba(255, 255, 255, .1);
  }
}

.wrapper-client-panel {
  .dashboard-sidebar {
    border-right: none;
    //box-shadow: none;
  }

  #header-container {
    #header {
      box-shadow: none;
      border-bottom: 1px solid #CCC;
      background-color: #f7f7f7;
    }
  }
}

.client-home-slider {
  .carousel-indicators {
    top: 7.5px;
    right: 15px;
    left: auto;
    bottom: auto;
    margin: 0;

    li {
      width: 16px;
      height: 16px;
      border-radius: 100%;
    }
  }

  .carousel-caption {
    float: left;
    text-align: left;
    background: rgba(255, 255, 255, .5);
    border-radius: 0;
    padding: 10px;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 2px solid #fff;

    h3 {
      margin-bottom: 10px;
    }
    p {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .carousel-control-prev, .carousel-control-next{
    top: 15px;
    display: block;
    width: auto;
  }
  .carousel-control-prev {
    left: 10px;
  }
  .carousel-control-next {
    right: auto;
    left: 30px;
  }
}