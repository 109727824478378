.admin-mobile-menu {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 999;
  width: 100%;
  height: 100vh;

  .top-bar {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  ul {
    padding: 0;

    li {
      width: 100%;
      list-style: none;
      margin-bottom: 25px;

      a {
        color: #484848;
        font-size: 21px;

        i {
          margin-right: 10px;
        }
      }

      ul {
        li {
          margin-bottom: 0;

          a {
            display: block;
            width: 100%;
            color: #fff;
            background-color: #484848;
            padding: 10px;
            transition: background-color .2s ease-in-out;
            font-size: 16px;

            &:active, &:focus, &:hover {
              color: #fff;
              background-color: #585858;
            }
          }
        }
      }
    }
  }
}

.mobile-panel-header {
  .item {
    flex-grow: 1;
  }

  .item4 {
    flex-grow: 2;
  }

  .item5 {
    flex-basis: 70px;
  }

  .MuiChip-label {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.mobile-profile {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  display: none;
  z-index: 999;
  width: 100%;
  height: 100vh;

  .mobile-profile-div {
    height: 75px;

    .mobile-profile-logo {
      width: auto !important;
      line-height: 70px;
      margin-left: 0 !important;
    }

    .mobile-profile-close {
      width: 48px;
      height: 48px;
    }
  }
}